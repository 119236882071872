import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/LayoutComponent"
import { Button, Container, Row, Col, Card } from "react-bootstrap"
import ScheduleButton from "../components/btns/ScheduleBtn"
import ContactForm from "../components/ContactForm"
import BackgroundImage from "../components/BackgroundImg"
import netlifyPromo from "../../site/settings/promo.json"
import SEO from "../components/seo"

import Image from "../components/CloudinaryImage"
import contactInfo from "../../site/settings/contact_info.json"
import netlifyStyle from "../../site/settings/style.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClipboardList,
  faWrench,
  faMoneyCheckAlt,
  faNetworkWired,
  faMobileAlt,
  faEnvelopeOpenText,
  faHardHat,
  faFan,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons"

function Home() {
  const mainHeadingFont = {
    color: netlifyStyle.secondary_font_color
  }

  const coloredSubtextFont = {
    color: netlifyStyle.secondary_font_color
  }

  const background1ContainerStyles = {
    minHeight: "50vh",
    maxWidth: "100vw",
    backgroundColor: "rgba(78, 78, 78, 0.651)",
    margin: 0
  }

  const btnStyle = {
    backgroundColor: netlifyStyle.brand_color,
    borderColor: netlifyStyle.brand_color
  }

  const background2ContainerStyles = {
    minHeight: "50vh",
    maxWidth: "100vw",
    backgroundColor: "rgba(194, 29, 45, 0.75)",
    margin: 0,
    padding: 0
  }

  const background3ContainerStyles = {
    minHeight: "50vh",
    maxWidth: "100vw",
    backgroundColor: "rgba(74, 71, 71, 0.8)",
    margin: "0 auto"
  }

  const serviceCardStyles = {
    width: "17rem",
    height: "100%",
    margin: "0.5rem auto"
  }

  const columnStyle = {
    margin: "0.5rem 0"
  }

  const logoColumnStyle = {
    maxHeight: "12rem"
  }

  const servicesIconStyle = {
    fontSize: "3rem",
    marginBottom: "0.5rem",
    color: netlifyStyle.brand_color
  }

  const testimonialStyle = {
    fontSize: "1rem"
  }

  const serviceLinkStyle = {
    color: netlifyStyle.brand_color
  }

  const imgStyle = { width: "50%", height: "20%" }

  const data = useStaticQuery(graphql`
    query markdown {
      pageContent: allMarkdownRemark(
        filter: { frontmatter: { page_title: { eq: "Home" } } }
      ) {
        edges {
          node {
            frontmatter {
              page_title
              main_prompt_1
              main_prompt_1_link

              section_1 {
                background_1
                main_heading
                description
                main_prompt
                bbb_description
                mitsubishi_descrption
                powershift_description
                powershift_prompt
                powershift_prompt_link
                certification_1_logo
                certification_2_logo
                certification_3_logo
              }
              services_intro {
                service_prompt
                service_heading
                service_description
                service_prompt_link
                pm_description
                finance_description
                finance_prompt
                finance_prompt_link
                controls_description
                construction_description
                construction_title
                pm_title
                finance_title
                service_title
                controls_title
                install_title
                install_description
              }

              section_3 {
                background_1
                main_heading
                description_1
                description_2
                main_prompt
                main_prompt_link
              }
              testimonials {
                testimonial_3_source
                testimonial_2_source
                testimonial_1_source
                testimonial_1
                testimonial_2
                testimonial_3
                testimonial_3_name
                testimonial_2_name
                testimonial_1_name
              }

              contact {
                background
                heading
              }
            }
          }
        }
      }
    }
  `)

  // save desired page data. Saving it in a object so it is easier to access. Page data we want for markup is stored inside frontmatter
  const pageContentData = data.pageContent.edges[0].node.frontmatter

  return (
    <div>
      <Layout>
        <SEO />
        <BackgroundImage
          markdownLink={pageContentData.section_1.background_1}
          style={{ margin: "0" }}
        >
          <Container
            className="p-4 d-flex justify-content-center align-items-center flex-column text-center h-100"
            style={background1ContainerStyles}
          >
            <div
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h1 style={mainHeadingFont}>
                {pageContentData.section_1.main_heading}
              </h1>
              <p style={mainHeadingFont}>
                {pageContentData.section_1.description}
              </p>

              <ScheduleButton
                href="https://book.housecallpro.com/book/DNA-AIR-CONDITIONING--HEATING/231c2ebb885f4ae88f01da8df7abaa4a">
                Schedule Now
              </ScheduleButton>
            </div>
          </Container>
        </BackgroundImage>
        <Container
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="mt-2"
        >
          <Row className="logo-section">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-column justify-content-center align-items-center"
              style={logoColumnStyle}
            >
              <Image
                className={"text-center"}
                style={imgStyle}
                markdownLink={pageContentData.section_1.certification_1_logo}
              />

              <p className="text-center font-weight-bold">
                {pageContentData.section_1.bbb_description}
              </p>
            </Col>
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <Image
                className={"mx-auto"}
                style={imgStyle}
                markdownLink={pageContentData.section_1.certification_2_logo}
              />
              <p className="text-center font-weight-bold">
                {pageContentData.section_1.mitsubishi_descrption}
              </p>
            </Col>
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <Image
                className={"mx-auto mb-1 "}
                style={imgStyle}
                markdownLink={pageContentData.section_1.certification_3_logo}
              />

              <p className="text-center font-weight-bold">
                {pageContentData.section_1.powershift_description}
              </p>
              <a
                className="text-center font-weight-bold"
                style={{ color: netlifyStyle.brand_color }}
                href={pageContentData.section_1.powershift_prompt_link}
              >
                {pageContentData.section_1.powershift_prompt}
              </a>
            </Col>
          </Row>
        </Container>
        <hr style={{ color: netlifyStyle.brand_color }} />
        <Container>
          <h3 className="text-center">
            {pageContentData.services_intro.service_heading}
          </h3>
          <Row className="d-flex mt-4 mx-auto mb-lg-3 ">
            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={columnStyle}
              xs={12}
              lg={4}
            >
              <Card style={serviceCardStyles}>
                <Card.Body className="text-center">
                  <Card.Title>
                    {" "}
                    {pageContentData.services_intro.service_title}
                  </Card.Title>

                  <FontAwesomeIcon
                    style={servicesIconStyle}
                    icon={faWrench}
                  />
                  <Card.Text>
                    {pageContentData.services_intro.service_description}
                  </Card.Text>
                  <a href={pageContentData.services_intro.service_prompt_link}>
                    <Button style={btnStyle}>
                      {pageContentData.services_intro.service_prompt}
                    </Button>
                  </a>
                </Card.Body>
              </Card>
            </Col>

            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={columnStyle}
              xs={12}
              lg={4}
            >
              <Card style={serviceCardStyles}>
                <Card.Body className="text-center">
                  <Card.Title>
                    {pageContentData.services_intro.pm_title}
                  </Card.Title>
                  <FontAwesomeIcon
                    style={servicesIconStyle}
                    icon={faClipboardList}
                  />
                  <Card.Text>
                    {pageContentData.services_intro.pm_description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={columnStyle}
              xs={12}
              lg={4}
            >
              <Card style={serviceCardStyles}>
                <Card.Body className="text-center">
                  <Card.Title>
                    {pageContentData.services_intro.finance_title}
                  </Card.Title>

                  <FontAwesomeIcon
                    style={servicesIconStyle}
                    icon={faMoneyCheckAlt}
                  />

                  <Card.Text>
                    {pageContentData.services_intro.finance_description}
                  </Card.Text>
                  <a
                    className="text-uppercase font-weight-bold"
                    style={serviceLinkStyle}
                    href={pageContentData.services_intro.finance_prompt_link}
                  >
                    {pageContentData.services_intro.finance_prompt}{" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center  mb-4 mx-auto">
            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={columnStyle}
              xs={12}
              lg={4}
            >
              <Card style={serviceCardStyles}>
                <Card.Body className="text-center">
                  <Card.Title>
                    {pageContentData.services_intro.controls_title}
                  </Card.Title>

                  <FontAwesomeIcon
                    style={servicesIconStyle}
                    icon={faNetworkWired}
                  />

                  <Card.Text>
                    {pageContentData.services_intro.controls_description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={columnStyle}
              xs={12}
              lg={4}
            >
              <Card style={serviceCardStyles}>
                <Card.Body className="text-center">
                  <Card.Title>
                    {pageContentData.services_intro.construction_title}
                  </Card.Title>

                  <FontAwesomeIcon
                    style={servicesIconStyle}
                    icon={faHardHat}
                  />

                  <Card.Text>
                    {pageContentData.services_intro.construction_description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={columnStyle}
              xs={12}
              lg={4}
            >
              <Card style={serviceCardStyles}>
                <Card.Body className="text-center">
                  <Card.Title>
                    {pageContentData.services_intro.install_title}
                  </Card.Title>

                  <FontAwesomeIcon
                    style={servicesIconStyle}
                    icon={faFan}
                  />

                  <Card.Text>
                    {pageContentData.services_intro.install_description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <BackgroundImage markdownLink={pageContentData.section_3.background_1}>
          <Container
            className="text-center p-3 my-0 mx-auto d-flex flex-column justify-content-center align-items-center"
            style={background2ContainerStyles}
          >
            <div
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h2 style={mainHeadingFont}>
                {pageContentData.section_3.main_heading}
              </h2>
              <p style={coloredSubtextFont}>
                {pageContentData.section_3.description_1}
              </p>
              <p style={coloredSubtextFont}>
                {pageContentData.section_3.description_2}
              </p>
            </div>
          </Container>
        </BackgroundImage>

        <Container>
          <Row className="my-4 d-flex justify-content-center">
            <Col
              // data-sal="slide-up"
              // data-sal-delay="300"
              // data-sal-easing="ease"
              xs={12}
              lg={4}
              className="mb-3"
            >
              <Card style={{ height: "100%" }}>
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <p style={testimonialStyle}>
                      {pageContentData.testimonials.testimonial_1}
                    </p>
                    <footer className="blockquote-footer">
                      {pageContentData.testimonials.testimonial_1_name}
                      <cite style={{ color: " #F40F18" }} title="Source Title">
                        {" "}
                        {pageContentData.testimonials.testimonial_1_source}
                      </cite>
                    </footer>
                  </blockquote>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} lg={4} className="mb-3">
              <Card
                // data-sal="slide-up"
                // data-sal-delay="300"
                // data-sal-easing="ease"
                // className="h-100"
                style={{ height: "100%" }}
              >
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <p style={testimonialStyle}>
                      {pageContentData.testimonials.testimonial_2}
                    </p>
                    <footer className="blockquote-footer">
                      {pageContentData.testimonials.testimonial_2_name}
                      <cite style={{ color: "#F40F18" }} title="Source Title">
                        {" "}
                        {pageContentData.testimonials.testimonial_2_source}
                      </cite>
                    </footer>
                  </blockquote>
                </Card.Body>
              </Card>
            </Col>
            <Col
              // data-sal="slide-up"
              // data-sal-delay="300"
              // data-sal-easing="ease"
              className="mb-3"
              xs={12}
              lg={4}
            >
              <Card style={{ height: "100%" }}>
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <p style={testimonialStyle}>
                      {pageContentData.testimonials.testimonial_3}
                    </p>
                    <footer className="blockquote-footer">
                      {pageContentData.testimonials.testimonial_3_name}
                      <cite style={{ color: "#F40F18" }} title="Source Title">
                        {" "}
                        {pageContentData.testimonials.testimonial_3_source}
                      </cite>
                    </footer>
                  </blockquote>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <BackgroundImage markdownLink={pageContentData.contact.background}>
          <Container
            className="text-center flex-column justify-content-center"
            style={background3ContainerStyles}
          >
            <h1
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="d-none d-lg-block"
              style={mainHeadingFont}
            >
              {pageContentData.contact.heading}
            </h1>
            <Row className="mt-4 d-flex justify-content-between align-items-center ">
              <Col
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease"
                xs={12}
                lg={6}
                className="mt-4"
              >
                <h2 style={mainHeadingFont}>
                  {netlifyPromo.mailchimp_form_prompt}
                </h2>
                <p style={mainHeadingFont}>
                  {netlifyPromo.mailchimp_form_description}
                </p>
                <a
                  style={coloredSubtextFont}
                  href={"mailto:" + contactInfo.email}
                >
                  <Button style={btnStyle}>
                    <FontAwesomeIcon
                      icon={faEnvelopeOpenText}
                    />{" "}
                    {contactInfo.email}
                  </Button>
                </a>{" "}
                <a style={coloredSubtextFont} href={"tel:" + contactInfo.phone}>
                  <Button style={btnStyle}>
                    <FontAwesomeIcon icon={faMobileAlt} />{" "}
                    {contactInfo.phone}
                  </Button>
                </a>
              </Col>
              <Col>
                <Container
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  className="mt-5"
                >
                  <ContactForm />
                </Container>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>
      </Layout>
    </div>
  )
}

export default Home
